@import 'custom-theme.scss';

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.7em;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.mat-raised-button {
  height: 2.5em;
  text-transform: uppercase;

  .mat-button-wrapper {
    color: white;
  }
}

mat-spinner {
  &.centered-spinner {
    position: fixed;
    z-index: 9999;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.mat-tooltip {
  word-wrap: break-word;
}

// Fix for breaking change of 2.0.0-beta.10
.mat-form-field {
  width: 100% !important;
}

// general styling for cards on detail pages
.detail-card {
  margin-bottom: 1em;

  mat-card-title {
    display: flex;
    flex-direction: row;
    color: mat-color($accent);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    word-break: break-all;
    cursor: pointer;
  }

  .mat-card-label {
    font-weight: bold;
    color: mat-color($accent);
    cursor: pointer;

    &.title {
      padding: 0.2em;
      font-size: 1.3*$font-size-general;
    }

    &.child {
      font-size: 1.3*$font-size-general;
    }

    &.property {
      font-size: 1.1*$font-size-general;
    }
  }

  &.mat-card {
    padding: 1em;
    color: mat-color($font-grey);
    border-radius: 0.2em;
  }

  .mat-card-content {
    margin: 1em !important;
    padding: 0.3em;
  }

  .mat-grid-list {
    margin: 1em 0;
  }

  figure {
    justify-content: flex-start !important;
  }
}

mat-chip {
  color: white !important;
  margin: 0.1em 0.1em;
  font-size: 0.7em;

  &.chip-grey {
    background-color: mat-color($font-grey, 500) !important;

    &.brighter {
      background-color: mat-color($font-grey, 400) !important;
    }
  }

  &.chip-warn {
    background-color: mat-color($warn, 400) !important;
  }

  &.chip-success {
    background-color: mat-color($success) !important;
  }

  &.chip-accent {
    background-color: mat-color($accent, 400) !important;

    &.brighter {
      opacity: 0.3 !important;
    }
  }
}

mat-grid-tile {
  overflow: auto;
  text-overflow: ellipsis;
}

.action-dialog {
  .mat-dialog-title {
    background-color: mat-color($accent);
    padding: 1em;

    /* This is very hacky, but works for the moment. I'm not able to call the selector of the mat-dialog */
    margin: -24px -24px 0 -24px;
    color: white;
    margin-bottom: 1.5em;

    h2 {
      color: white;
      margin: 0;
    }
  }

  .mat-dialog-content {
    margin-bottom: 1.4em;
  }

  .mat-dialog-actions:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .validation-error {
    margin-top: 0.5em;
    font-size: $font-size-error-messages;
    color: mat-color($warn);
  }
}

div.mat-select-content {
  max-width: 100%;

  .mat-option {
    font-size: 1em !important;
  }
}

div.mat-select-trigger {
  font-size: 1em;
}

div.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: mat-color($accent);
}

// for multi selects
div.mat-primary .mat-pseudo-checkbox-checked {
  background-color: mat-color($accent);
}

// For the tab-group on Inserenten -> Firmenprofile.(Company-profile-component) Doesn't work in the component
.company-profile-tab {
  .mat-tab-header {
    margin-bottom: 1.5em;
    border-bottom: 0;
  }
}

// For the direct filters on the tables
.direct-filter-checkbox {
  ::ng-deep div.mat-checkbox-inner-container {
    visibility: hidden;
  }
}

.radio-labels-small {
  .mat-radio-label-content {
    font-size: 0.85em;
  }
}

.mat-select-wrapper-no-bottom-margin {
  mat-select {
    margin-bottom: 0 !important;
  }
}

// Material changed there input underlines to be darker, we don't want that!

.mat-select-underline {
  background-color: mat-color($material-grey-for-inputs) !important;
}

.mat-form-field-underline {
  background-color: mat-color($material-grey-for-inputs) !important;
}

.mat-form-field-underline.mat-disabled {
  background-color: white !important;
}

.mat-checkbox-frame {
  border-color: mat-color($font-grey, 500) !important;
}

.mat-radio-outer-circle {
  border-color: mat-color($font-grey, 500) !important;
}

.mat-radio-label {
  margin-bottom: 2px !important;
}

.mat-checkbox-label {
  color: mat-color($font-grey, 800);
}

.datatable-body-cell-label {
  color: mat-color($font-grey, 800);
}

/** IE 11 fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // Fix width of mat-select drop-down
  // https://github.com/angular/material2/issues/11609#issuecomment-400439149
  div.mat-select-panel {
    min-width: inherit !important;
  }

  div.cdk-overlay-pane {
    display: block;
  }
}

input[readonly]{
  color: #00000061;
}
