/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import 'custom-theme.scss';
@import 'variables';
@import 'material-custom-styles.scss';
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src:
    local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(assets/material-icons.woff2) format('woff2'),
    url(assets/material-icons.woff) format('woff');
}

html {
  background: url(images/background.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  min-width: $application-min-width;
}

body {
  margin: 0 0 2em 0;
  font-family: 'Roboto', sans-serif;
  font-size: $font-size-general;
  color: mat.get-color-from-palette($mat-font-palette, 600);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-view-container {
  margin: 6.5em auto 6.5em;
  width: $application-content-width;
}

h1,
h2,
h3,
h4 {
  color: mat-color($accent);
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

a {
  color: mat.get-color-from-palette($mat-font-palette, 600);
  text-decoration: none;
}

p {
  margin: 0;
  color: mat.get-color-from-palette($mat-font-palette, 600);
}

hr {
  border: 1px solid;
  color: mat-color($mat-font-palette, 300);
  margin: 0;
  width: 100%;
}

app-confirm-cookie {
  width: 100%;
}

ngx-datatable {
  border-radius: 0.5em;
  padding: 0.5em;

  .datatable-body-cell-label {
    font-size: 1em;
  }

  .datatable-header-cell {
    font-size: 1em;

    span {
      font-weight: bold;
      color: mat-color($accent);
    }
  }

  .page-count {
    font-size: 0.9em;
  }
}

.detail-page-wrapper {
  width: 80%;
  margin: auto;
}

.default-snack-bar {
  background: mat-color($accent) !important;
}

.error-snack-bar {
  background: mat-color($warn) !important;
}

.full-width {
  width: 100%;
}

.display-container-grey-underlay {
  h2 {
    margin-top: 0;
  }

  background-color: mat.get-color-from-palette($mat-font-palette, 100);
  border-radius: 1em;
  padding: 1em 1.5em;
  margin-bottom: 1em;

  .button-row {
    margin-top: 1em;
  }
}

.error-message-style {
  margin-top: 0.5em;
  font-size: $font-size-error-messages;
  color: mat-color($warn);
}

.textarea-large {
  height: 15em;
}

.long-string {
  word-wrap: break-word;
  hyphens: auto;
}

.input-fields-wrapper {
  padding: 2em 3em 2em 3em;
}

.chiffre-address-line-break {
  white-space: pre-wrap;
}

@media only screen and (max-width: 1380px) {
  .ngx-datatable {
    font-size: $font-size-media-screen !important;

    .datatable-footer .datatable-pager .pager .ngx-datatable .datatable-footer .datatable-pager .pager li {
      font-size: $font-size-media-screen;
    }

    .page-count {
      font-size: $font-size-media-screen;
    }
  }

  nav {
    padding: 0;

    span {
      font-size: $font-size-media-screen;

      .mat-button {
        padding: 0 0.9em 0 0.9em;
      }
    }
  }
}

.textarea-middle {
  height: 5em;
}

.pointer {
  cursor: pointer;
}

.step-wrapper {
  margin: 0 2em;
  padding: 0 !important;
}

.step-content-wrapper {
  padding: 2em 3em;
}

.multiline-tooltip{
  white-space: pre-line;
}
