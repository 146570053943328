
$application-min-width: 1280px;
$font-size-general: 14px;
$font-size-media-screen: 12px;
$font-size-error-messages: 10px;
$font-size-h1: 1.5 * $font-size-general;
$font-size-h2: 1.3 * $font-size-general;
$font-size-h3: 1.1 * $font-size-general;

$small-button-font-size: 0.9 * $font-size-general;

$application-content-width: 95%;
$application-content-background: #e4ebea;
