@use '@angular/material' as mat;
@import '~@angular/material/theming';

@include mat-core();

@import 'variables';

// please do not delete entries here. The unused ones are black because it wont compile otherwise.

$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);

$mat-font-palette: (
  50: #fafafa,
  100: #f2f2f2,
  200: #eee,
  300: #e0e0e0,
  400: #b3b3b3,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #fff,
  A200: #eee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $white-87-opacity,
  )
);

$mat-primary-palette: (
  50: #8fafc4,
  100: #7ea3bb,
  200: #6d98b2,
  300: #5d8ca9,
  400: #527f9b,
  500: #49718a,
  600: #406379,
  700: #375669,
  800: #2f4858,
  900: #263a47,
  A100: #a0bbcc,
  A200: #b0c7d5,
  A400: #c1d3de,
  A700: #1d2d37,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-warn-palette: (
  50: #ffdddc,
  100: #ffc4c3,
  200: #ffaca9,
  300: #ff9390,
  400: #ff7a76,
  500: #ff615d,
  600: #ff4843,
  700: #ff2f2a,
  800: #ff1610,
  900: #f60600,
  A100: #fff6f6,
  A200: #fff,
  A400: #fff,
  A700: #dc0500,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-success-palette: (
  50: #ccebbe,
  100: #bde4ab,
  200: #aede97,
  300: #9ed884,
  400: #8fd270,
  500: #9ccc6e,
  600: #71c64a,
  700: #63bb3b,
  800: #59a835,
  900: #4f942f,
  A100: #dbf1d1,
  A200: #eaf7e5,
  A400: #fafdf8,
  A700: #448129,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$primary: mat.define-palette($mat-primary-palette, 50);
$accent: mat.define-palette($mat-primary-palette, 500);
$warn: mat.define-palette($mat-warn-palette, 400);
$success: mat.define-palette($mat-success-palette, 500);
$font-grey: mat.define-palette($mat-font-palette, 600);
$font-white: mat.define-palette($mat-font-palette, 50);
$material-grey-for-inputs: mat.define-palette($mat-font-palette, 300);
$background-medium-grey: mat.define-palette($mat-font-palette, 600);
$background-dark-grey: mat.define-palette($mat-font-palette, 800);
$input-field-placeholder-color: rgb(0 0 0 / 44%);

$theme: mat.define-light-theme((color: (primary: $primary, accent: $accent, warn: $warn)));

@include angular-material-theme($theme);

/* fixes keyboard selection on mat-select inputs (sometimes the MatSelectModule caused
different orders of css imports. Then the height calculation went wrong with 16px
and the selection jumped to the wrong element) */
$mat-option-typography: mat.define-typography-config(
  $font-family: 'Roboto, sans-serif',
  $subheading-2: mat.define-typography-level(14px, 24px, 500)
);
@include mat-option-typography($mat-option-typography);
